// src/components/Player.js

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getSignedUrl } from '../utils/getSignedUrl';

// Styled Audio Component
const StyledAudio = styled.audio`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;

  /* Optional: Prevent text selection and other unwanted interactions */
  user-select: none;

  /* Hide the default context menu */
  &:context-menu {
    display: none;
  }
`;

// Use React.forwardRef to forward the ref to the audio element
const Player = React.forwardRef(({ songFile }, ref) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    const fetchSignedUrl = async () => {
      const signedUrl = await getSignedUrl(songFile);
      if (signedUrl) {
        setSrc(signedUrl);
      }
    };
    fetchSignedUrl();
  }, [songFile]);

  return (
    <StyledAudio
      ref={ref}
      controls
      controlsList="nodownload noremoteplayback nofullscreen"
      onContextMenu={(e) => e.preventDefault()}
    >
      {src ? <source src={src} type="audio/mpeg" /> : null}
      Your browser does not support the audio element.
    </StyledAudio>
  );
});

export default Player;