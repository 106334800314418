// src/components/Badge.js
import React from 'react';
import styled, { css } from 'styled-components';
import { FaCheckCircle, FaStar } from 'react-icons/fa'; // Import necessary icons

// Styled Component for Badge
const StyledBadge = styled.span`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  cursor: default;
  width: fit-content;

  /* Common icon spacing */
  svg {
    margin-right: 5px;
  }

  /* Conditional Styling Based on Badge Type */
  ${(props) =>
    props.type === 'Free' &&
    css`
      border: 1px solid #4caf50; /* Green Outline */
      color: #4caf50; /* Green Text */
    `}

  ${(props) =>
    props.type === 'Pro' &&
    css`
      border: 1px solid #000000; /* Black Outline */
      color: #000000; /* Black Text */
    `}

  ${(props) =>
    props.type === 'Unlimited' &&
    css`
      border: 1px solid transparent; /* Remove default border */
      background: linear-gradient(45deg, #ff00cc, #333399, #00ffff, #ff00cc);
      background-size: 300% 300%;
      color: #ffffff; /* White Text for contrast */
      animation: gradientAnimation 3s ease infinite;

      @keyframes gradientAnimation {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
    `}
`;

// Badge Component
const Badge = ({ type, children }) => {
  // Select icon based on type
  let Icon;
  switch (type) {
    case 'Free':
      Icon = FaCheckCircle;
      break;
    case 'Pro':
      Icon = FaCheckCircle; // You can choose a different icon for Pro if desired
      break;
    case 'Unlimited':
      Icon = FaStar; // Using star icon for Unlimited
      break;
    default:
      Icon = FaCheckCircle;
  }

  return (
    <StyledBadge type={type}>
      <Icon />
      {children}
    </StyledBadge>
  );
};

export default Badge;