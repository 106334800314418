// src/pages/SongPage.js

import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Player from '../components/Player';
import { Helmet } from 'react-helmet-async';
import { getSongById } from '../utils/songUtils';
import { getSignedUrl } from '../utils/getSignedUrl';
import {
  FaStar,
  FaBriefcase,
  FaSun,
  FaFilm,
  FaLeaf,
  FaPeace,
  FaBolt,
  FaMountain,
  FaGuitar,
  FaSadTear,
  FaCoffee,
  FaHeart,
  FaSmile,
  FaCompactDisc,
  FaCloud,
  FaMicrochip,
  FaFire,
  FaMicrophone,
  FaDrum,
  FaQuestionCircle,
  FaMusic,
  FaGift,
  FaWater
} from 'react-icons/fa';
import { GiAccordion, GiClover, GiMusicalNotes, GiTrumpet, GiMeditation } from 'react-icons/gi';
import { AuthContext } from '../context/AuthContext';

// Tag Colors Mapping (Muted and Darker Colors)
const tagColors = {
  Corporate: '#2F4F4F', // Dark Slate Gray
  Uplifting: '#556B2F', // Dark Olive Green
  Cinematic: '#4B0082', // Indigo
  Calm: '#4682B4', // Steel Blue
  Piano: '#6A5ACD', // Slate Blue
  Peaceful: '#2E8B57', // Sea Green
  Energetic: '#B22222', // Firebrick
  Epic: '#800000', // Maroon
  Guitar: '#8B4513', // Saddle Brown
  Sad: '#00008B', // Dark Blue
  Lofi: '#708090', // Slate Gray
  Romantic: '#8B0000', // Dark Red
  Happy: '#DAA520', // Goldenrod
  Pop: '#9932CC', // Dark Orchid
  Vaporwave: '#2F4F4F', // Dark Slate Gray
  Electronic: '#2F4F4F', // Dark Slate Gray
  Angry: '#8B0000', // Dark Red
  'Hip-Hop': '#000000', // Black
  Beats: '#696969', // Dim Gray
  Mysterious: '#4B0082', // Indigo
  Accordion: '#800000', // Maroon
  Celtic: '#556B2F', // Dark Olive Green
  Classical: '#1E90FF', // Dodger Blue
  Holiday: '#FF4500', // Orange Red
  Trumpet: '#8B4513', // Saddle Brown
  Meditation: '#9932CC', // Dark Orchid
};

// Tag Icons Mapping
const tagIcons = {
  Ambient: FaWater,
  Corporate: FaBriefcase,
  Uplifting: FaSun,
  Cinematic: FaFilm,
  Calm: FaLeaf,
  Piano: FaMusic,
  Peaceful: FaPeace,
  Energetic: FaBolt,
  Epic: FaMountain,
  Guitar: FaGuitar,
  Sad: FaSadTear,
  Lofi: FaCoffee,
  Romantic: FaHeart,
  Happy: FaSmile,
  Pop: FaCompactDisc,
  Vaporwave: FaCloud,
  Electronic: FaMicrochip,
  Angry: FaFire,
  'Hip-Hop': FaMicrophone,
  Beats: FaDrum,
  Mysterious: FaQuestionCircle,
  Accordion: GiAccordion,
  Celtic: GiClover,
  Classical: GiMusicalNotes,
  Holiday: FaGift,
  Trumpet: GiTrumpet,
  Meditation: GiMeditation,
};

// Styled Components
const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e3e3e3; /* Pale Stone Gray */

  /* Adjust padding for smaller screens */
  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const SongTitle = styled.h1`
  font-family: 'DM Serif Text', serif;
  color: #2e2e2e;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const FeaturedPill = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -5px;
  padding: 5px 10px;
  border: 1px solid #daa520; /* Goldenrod Outline */
  border-radius: 20px;
  color: #daa520; /* Goldenrod Text */
  font-family: 'Rubik', sans-serif;
  font-size: 0.9em;

  svg {
    margin-right: 5px;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;
`;

const TagPill = styled.a`
  && { /* Increased specificity using double ampersand */
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid ${(props) => props.color || '#000'};
    border-radius: 20px;
    color: ${(props) => props.color || '#000'};
    font-family: 'Rubik', sans-serif;
    font-size: 0.9em;
    font-weight: 400;
    text-decoration: none; /* Remove underline */
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.color || '#000'};
      color: #fff; /* Change text color to white */
    }

    svg {
      margin-right: 5px;
    }
  }
`;

const BpmKeyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
`;

const BpmKeyPill = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #696969; /* Dim Gray Outline */
  border-radius: 0px; /* Changed from 20px to 0px for square corners */
  color: #696969; /* Dim Gray Text */
  font-family: 'Rubik', sans-serif;
  font-size: 0.9em;
`;

const ArtistContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 20px;
`;

const Avatar = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ArtistName = styled.h3`
  font-family: 'Rubik', sans-serif;
  color: #4a4a4a;
  text-align: center;
  margin-left: -4px;
`;

const SongTags = styled.div`
  margin: 10px 0 20px 0;
  text-align: center;
`;

const AlbumArt = styled.div`
  width: 400px;
  height: 400px;
  margin: 20px 0px;
  background-image: url(${(props) => props.$image});
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer; /* Indicate that it's clickable */

  /* Responsive adjustments */
  @media (max-width: 1024px) {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 768px) {
    width: 250px;
    height: 250px;
  }

  @media (max-width: 480px) {
    width: 90vw; /* 90% of viewport width */
    height: 90vw; /* Maintain square aspect ratio */
  }
`;

const PlayerWrapper = styled.div`
  margin: 30px 0;
  width: 100%;
  max-width: 400px; /* Slightly reduced max-width */
  text-align: center;

  audio {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    outline: none; /* Remove default focus outline */
    color: #2e2e2e;
  }

  /* Optional: Customize the audio controls appearance */
  audio::-webkit-media-controls-panel {
    background-color: #ffffff;
    border-radius: 5px;
  }

  audio::-webkit-media-controls-play-button,
  audio::-webkit-media-controls-pause-button,
  audio::-webkit-media-controls-volume-slider {
    filter: brightness(0.7);
  }

  /* For Firefox */
  audio::-moz-focus-outer {
    border: 0;
  }
`;

// DownloadButton with dynamic styles based on disabled state
const DownloadButton = styled.button`
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  text-decoration: none;
  color: ${(props) => (props.disabled ? '#a0a0a0' : '#fff')}; /* Grey or white text */
  background-color: ${(props) => (props.disabled ? '#cccccc' : '#2e2e2e')}; /* Grey or black background */
  border: none;
  border-radius: 25px;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')}; /* Not-allowed or pointer cursor */
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#cccccc' : '#444')}; /* Maintain grey on hover if disabled */
  }
`;

// Updated DownloadDisclaimer message
const DownloadDisclaimer = styled.div`
  margin-top: 10px;
  text-align: center;

  p {
    font-family: 'Rubik', sans-serif;
    color: #a0a0a0; /* Lighter Gray */
    font-size: 12px;
    margin: 5px 0;
    white-space: pre-line; /* To respect newline characters */
  }
`;

const DownloadStatusBar = styled.div`
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #e0f7fa; /* Light Cyan Background */
  color: #006064; /* Dark Cyan Text */
  border: 1px solid #b2ebf2; /* Cyan Border */
  border-radius: 20px;
  font-family: 'Rubik', sans-serif;
  font-size: 0.9em;
  text-align: center;
  width: fit-content;
`;

const ByText = styled.span`
  font-family: 'Rubik', sans-serif;
  color: #4a4a4a;
  margin-right: 8px; /* Adjust spacing as needed */
  font-size: 1em; /* Ensure consistent sizing */
`;

const SongPage = () => {
  const { songId } = useParams();
  const { user, getDownloadStatus } = useContext(AuthContext); // Get user from AuthContext
  const [song, setSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [albumArtSrc, setAlbumArtSrc] = useState('');
  const [canDownload, setCanDownload] = useState(false); // New state to track download availability
  const [downloadLoading, setDownloadLoading] = useState(false); // State to manage download button loading
  const audioRef = useRef(null);

  useEffect(() => {
    const fetchSong = async () => {
      try {
        const songData = await getSongById(songId);
        setSong(songData);

        // Fetch signed URL for album art
        if (songData.song_art) {
          console.log('Raw song_art path:', songData.song_art);
          
          // Ensure no leading slash
          const artPath = songData.song_art.startsWith('/') 
            ? songData.song_art.slice(1) 
            : songData.song_art;
          
          console.log('Processed art path:', artPath);
          
          const signedArtUrl = await getSignedUrl(artPath);
          
          if (signedArtUrl) {
            console.log('Full Signed URL:', signedArtUrl);
            
            // Use fetch to verify the image
            try {
              // Optionally, you can check if the image exists
              const response = await fetch(signedArtUrl, { method: 'HEAD' });
              if (response.ok) {
                setAlbumArtSrc(signedArtUrl);
              } else {
                console.warn('Image not found, using fallback.');
                setAlbumArtSrc('/public/images/categories/ambient.jpg');
              }
            } catch (fetchError) {
              console.error('Image fetch error:', fetchError);
              setAlbumArtSrc('/public/images/categories/ambient.jpg');
            }
          } else {
            console.warn('No signed URL generated');
            setAlbumArtSrc('/public/images/categories/ambient.jpg');
          }
        } else {
          // If no song_art provided, use default
          setAlbumArtSrc('/public/images/categories/ambient.jpg');
        }
      } catch (error) {
        console.error('Error fetching song data:', error);
        setAlbumArtSrc('/public/images/categories/ambient.jpg');
      }
    };
    fetchSong();
  }, [songId]);

  useEffect(() => {
    const fetchDownloadStatus = async () => {
      if (!user) return;
  
      const status = await getDownloadStatus();
      setCanDownload(status.canDownload);
    };
  
    fetchDownloadStatus();
  }, [user, songId, getDownloadStatus]);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);

    audio.addEventListener('play', handlePlay);
    audio.addEventListener('pause', handlePause);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('play', handlePlay);
      audio.removeEventListener('pause', handlePause);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [song]);

  useEffect(() => {
    // If user is logged in, check download status
    const checkDownloadStatus = async () => {
      if (!user) return;

      try {
        const token = localStorage.getItem('token'); // Get token from localStorage
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/download-status`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCanDownload(data.canDownload); // Assuming API returns { canDownload: true/false }
        } else {
          console.error('Failed to fetch download status');
          setCanDownload(false);
        }
      } catch (error) {
        console.error('Error checking download status:', error);
        setCanDownload(false);
      }
    };

    checkDownloadStatus();
  }, [user, songId]);

  const handleAlbumArtClick = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  };

  const handleDownload = async () => {
    if (!user) {
      // Optionally, redirect to login or show a message
      alert('Please log in to download tracks.');
      return;
    }
  
    setDownloadLoading(true);
  
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/download/${song.song_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const downloadUrl = data.downloadUrl;
  
        // Trigger the download using the Fetch API to handle CORS and other issues
        const res = await fetch(downloadUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // If required by your backend
          },
        });
  
        if (res.ok) {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
  
          // Extract filename from the Content-Disposition header if available
          const contentDisposition = res.headers.get('Content-Disposition');
          let filename = `${song.song_title}.mp3`; // Default filename
  
          if (contentDisposition && contentDisposition.includes('filename=')) {
            filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
          }
  
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        } else {
          const errorData = await res.json();
          console.error('Download failed:', errorData.error);
          alert(`Download failed: ${errorData.error}`);
        }
  
        // Update the download status
        setCanDownload(false);
      } else if (response.status === 403) {
        // Download limit reached
        setCanDownload(false);
        alert('Upgrade to Tempo Stock Pro for more downloads.');
      } else {
        const errorData = await response.json();
        console.error('Download failed:', errorData.error);
        alert(`Download failed: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error during download:', error);
      alert('An unexpected error occurred during the download.');
    } finally {
      setDownloadLoading(false);
    }
  };

  if (!song) return <p>Loading...</p>;

  return (
    <PageContainer>
      {/* Dynamic Meta Tags with Helmet */}
      <Helmet>
        <title>{song.song_title} on Tempo Stock</title>
        <meta name="description" content={song.description || 'High quality AI stock music'} />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={song.song_title} />
        <meta property="og:description" content={song.description || 'High quality AI stock music'} />
        <meta property="og:image" content={albumArtSrc || 'https://www.tempostock.com/images/social.png'} />
        <meta property="og:url" content={`https://www.tempostock.com/songs/${song.song_id}`} />
        <meta property="og:type" content="music.song" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={song.song_title} />
        <meta name="twitter:description" content={song.description || 'High quality AI stock music'} />
        <meta name="twitter:image" content={albumArtSrc || 'https://www.tempostock.com/images/social.png'} />
        <meta name="twitter:url" content={`https://www.tempostock.com/songs/${song.song_id}`} />
      </Helmet>

      {song.song_featured && (
        <FeaturedPill>
          <FaStar />
          Featured Track
        </FeaturedPill>
      )}
      <SongTitle>{song.song_title}</SongTitle>
      <ArtistContainer>
        <ByText>by</ByText>
        {song.song_artist_art && (
          <Avatar
            src={`/images/user/avatars/${song.song_artist_art}`}
            alt={`${song.song_artist} Avatar`}
          />
        )}
        <ArtistName>{song.song_artist}</ArtistName>
      </ArtistContainer>
      <SongTags>
        <TagContainer>
          {song.song_tags.map((tag) => {
            const IconComponent = tagIcons[tag] || FaMusic;
            return (
              <TagPill
                key={tag}
                color={tagColors[tag] || '#000'}
                href={`/${tag.toLowerCase()}`}
              >
                <IconComponent />
                {tag}
              </TagPill>
            );
          })}
        </TagContainer>
      </SongTags>
      {albumArtSrc && (
        <AlbumArt
          $image={albumArtSrc}
          onClick={handleAlbumArtClick}
          title={isPlaying ? 'Pause' : 'Play'}
        />
      )}
      <BpmKeyContainer>
        <BpmKeyPill>{song.song_bpm}</BpmKeyPill>
        <BpmKeyPill>{song.song_key}</BpmKeyPill>
      </BpmKeyContainer>
      <PlayerWrapper>
        <Player ref={audioRef} songFile={song.song_file_wm} />
      </PlayerWrapper>

      {/* Download Button and Related Visuals */}
      {user ? (
        <>
          <DownloadButton
            onClick={handleDownload}
            disabled={!canDownload || downloadLoading}
          >
            {downloadLoading ? 'Downloading...' : 'Download Track'}
          </DownloadButton>

          {/* New Download Status Bar */}
          {canDownload && (
            <DownloadStatusBar>
              Weekly Download Remaining: 1
            </DownloadStatusBar>
          )}

          <DownloadDisclaimer>
            {canDownload ? (
              <>
                <p>
                  Upgrade to Tempo Stock Pro to download more tracks.
                </p>
                <p>
                  By downloading, you agree to Tempo Stock's Terms of Service and Privacy Policy. Free users must credit Tempo Stock for usage of downloaded tracks.
                </p>
              </>
            ) : (
              <p>
                Upgrade to Tempo Stock Pro to download more tracks.
              </p>
            )}
          </DownloadDisclaimer>
        </>
      ) : (
        <>
          <DownloadButton disabled>
            Download Track
          </DownloadButton>
          <DownloadDisclaimer>
            <p>
              Please <a href="/login">log in</a> to download tracks.
            </p>
          </DownloadDisclaimer>
        </>
      )}
    </PageContainer>
  );
};

export default SongPage;