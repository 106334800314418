// src/pages/HomePage.js
import React from 'react';
import SearchBar from '../components/SearchBar';
import CategorySection from '../components/CategorySection';
import MoodSection from '../components/MoodSection';

function HomePage() {
  return (
    <div>
      <h1 style={{ textAlign: 'center', marginTop: '70px', padding: '0 15px' }}>
  Find high quality stock music
</h1>
      <SearchBar />
      <CategorySection />
      <MoodSection />
    </div>
  );
}

export default HomePage;