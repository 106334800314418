import React from 'react';
import styled from 'styled-components';
import TopText from '../components/TopText'; // Ensure this component is available
import { FaShieldAlt } from 'react-icons/fa'; // Import an appropriate icon

// Shared Text Color
const textColor = '#555';

// Styled Components
const PrivacyContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin: 40px 20px; /* Top & Bottom: 40px, Left & Right: 20px */
  }
`;

const SectionTitle = styled.p`
  font-family: 'Rubik', sans-serif;
  color: ${textColor}; /* Updated from #6A6A6A to #555 */
  margin: 30px 0 15px;
  text-align: center;
  font-size: 1em;
`;

const SectionHeader = styled.h2`
  font-family: 'DM Serif Text', serif;
  color: ${textColor}; /* Updated from #333 to #555 */
  margin: 30px 0 15px;
  text-align: left;
  font-size: 1.5em;
`;

const SubSectionHeader = styled.h3`
  font-family: 'Rubik', sans-serif;
  color: ${textColor}; /* Updated from #333 to #555 */
  margin: 20px 0 10px;
  font-size: 1em;
`;

const Paragraph = styled.p`
  font-family: 'Rubik', sans-serif;
  color: ${textColor}; /* Ensures consistency */
  line-height: 1.6;
  margin-bottom: 15px;
`;

const UnorderedList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
`;

const OrderedList = styled.ol`
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 15px;
`;

const ListItem = styled.li`
  color: ${textColor}; /* Ensure list items match paragraph text */
  margin-bottom: 10px;
`;

const Link = styled.a`
  color: #1a0dab;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

// Privacy Policy Component
const PrivacyPolicy = () => {
  return (
    <>
      {/* Header Section with Icon and Title using TopText */}
      <TopText icon={FaShieldAlt} title="Privacy Policy" />
      <SectionTitle>Effective Date: December 10, 2024</SectionTitle>

      <PrivacyContainer>
        {/* Introduction */}
        <Paragraph>
          Tempo Stock (“we,” “our,” “us”) values your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you access or use our website and services (“Services”). By using Tempo Stock, you agree to this Privacy Policy.
        </Paragraph>

        {/* Definitions */}
        <UnorderedList>
          <ListItem><strong>“Personal Information"</strong> refers to any information that can be used to identify an individual, such as name, email address, payment information, etc.</ListItem>
          <ListItem><strong>"Processing"</strong> means any operation performed on personal data, whether automated or not, such as collection, storage, use, or deletion.</ListItem>
          <ListItem><strong>"Data Controller"</strong> refers to Tempo Stock, the entity that determines the purposes and means of processing personal data.</ListItem>
          <ListItem><strong>"Data Processor"</strong> refers to any third party that processes personal data on behalf of Tempo Stock.</ListItem>
          <ListItem><strong>"Services"</strong> encompass the website, products, and any other offerings provided by Tempo Stock.</ListItem>
        </UnorderedList>

        {/* Section 1: Information We Collect */}
        <SectionHeader>1. Information We Collect</SectionHeader>
        <Paragraph>We may collect the following types of information:</Paragraph>

        {/* Subsection 1.1: Personal Information */}
        <SubSectionHeader>1.1 Personal Information:</SubSectionHeader>
        <UnorderedList>
          <ListItem><strong>Identity data:</strong> Name, email address, and payment information when you create an account or make a purchase.</ListItem>
          <ListItem><strong>Contact data:</strong> Contact details when you communicate with us.</ListItem>
          <ListItem><strong>Account data:</strong> Username, password, and other credentials.</ListItem>
        </UnorderedList>

        {/* Subsection 1.2: Usage Information */}
        <SubSectionHeader>1.2 Usage Information:</SubSectionHeader>
        <UnorderedList>
          <ListItem><strong>Technical data:</strong> IP address, browser type, operating system, and device information.</ListItem>
          <ListItem><strong>Behavioral data:</strong> Pages visited, time spent on the site, and other activity logs.</ListItem>
          <ListItem><strong>Interaction data:</strong> Information about how you interact with our Services, such as feature usage and preferences.</ListItem>
        </UnorderedList>

        {/* Subsection 1.3: Cookies and Tracking Technologies */}
        <SubSectionHeader>1.3 Cookies and Tracking Technologies:</SubSectionHeader>
        <UnorderedList>
          <ListItem><strong>Cookies:</strong> In the future, we may use cookies and similar technologies to enhance user experience, analyze site usage, and deliver personalized content. Should we do this, consent will be required. We do not use cookies that require consent at this time.</ListItem>
          <ListItem><strong>Web beacons and pixels:</strong> Used to monitor the effectiveness of our marketing campaigns and understand user behavior.</ListItem>
          <ListItem><strong>Third-party trackers:</strong> Tools provided by third parties (e.g., Google Analytics) to collect and analyze data.</ListItem>
        </UnorderedList>

        {/* Section 2: Legal Bases for Processing Your Information */}
        <SectionHeader>2. Legal Bases for Processing Your Information</SectionHeader>
        <Paragraph>We process your personal information based on the following legal grounds:</Paragraph>
        <OrderedList>
          <ListItem><strong>Consent:</strong> Where you have given explicit consent for specific purposes.</ListItem>
          <ListItem><strong>Performance of a Contract:</strong> To fulfill our contractual obligations to you, such as processing payments and providing Services.</ListItem>
          <ListItem><strong>Legal Obligations:</strong> To comply with legal requirements, such as tax laws and data protection regulations.</ListItem>
          <ListItem><strong>Legitimate Interests:</strong> For purposes that are necessary for our legitimate interests, provided they do not override your rights and freedoms. This includes improving our Services, marketing, and fraud prevention.</ListItem>
        </OrderedList>

        {/* Section 3: Data Retention */}
        <SectionHeader>3. Data Retention</SectionHeader>
        <Paragraph>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. The criteria used to determine retention periods include:</Paragraph>
        <UnorderedList>
          <ListItem><strong>Compliance with Legal Obligations:</strong> Retaining data to comply with applicable laws, regulations, or legal processes.</ListItem>
          <ListItem><strong>Business Needs:</strong> Retaining data to resolve disputes, enforce our agreements, and maintain security.</ListItem>
          <ListItem><strong>User Requests:</strong> Deleting or anonymizing data upon user request, subject to legal obligations.</ListItem>
        </UnorderedList>
        <Paragraph>When personal data is no longer required, we will securely delete or anonymize it.</Paragraph>

        {/* Section 4: International Data Transfers */}
        <SectionHeader>4. International Data Transfers</SectionHeader>
        <Paragraph>Your personal information may be transferred to and maintained on servers located outside your country of residence. These jurisdictions may have different data protection laws than your own. We ensure that any international data transfers comply with applicable laws by implementing appropriate safeguards, such as:</Paragraph>
        <UnorderedList>
          <ListItem><strong>Standard Contractual Clauses (SCCs):</strong> Ensuring third-party processors adhere to contractual obligations regarding data protection.</ListItem>
          <ListItem><strong>Adequate Safeguards:</strong> Relying on countries recognized by the European Commission as providing adequate data protection.</ListItem>
        </UnorderedList>
        <Paragraph>By using our Services, you consent to the transfer of your information to countries outside your jurisdiction, including the United States.</Paragraph>

        {/* Section 5: How We Use Your Information */}
        <SectionHeader>5. How We Use Your Information</SectionHeader>
        <Paragraph>We use the information collected to:</Paragraph>
        <OrderedList>
          <ListItem>Provide and improve our Services.</ListItem>
          <ListItem>Process payments and fulfill orders.</ListItem>
          <ListItem>Communicate with you regarding your account, purchases, or updates.</ListItem>
          <ListItem>Personalize your experience on our platform.</ListItem>
          <ListItem>Protect against fraud and ensure the security of our platform.</ListItem>
          <ListItem>Comply with legal obligations.</ListItem>
        </OrderedList>

        {/* Section 6: Sharing Your Information */}
        <SectionHeader>6. Sharing Your Information</SectionHeader>
        <Paragraph>We do not sell or rent your personal information to third parties. However, we may share your information:</Paragraph>
        <OrderedList>
          <ListItem><strong>With service providers:</strong> For payment processing, analytics, and customer support.</ListItem>
          <ListItem><strong>With legal authorities:</strong> If required to comply with laws or legal processes.</ListItem>
          <ListItem><strong>In business transfers:</strong> If Tempo Stock undergoes a merger, acquisition, or sale of assets.</ListItem>
        </OrderedList>

        {/* Section 7: Data Security */}
        <SectionHeader>7. Data Security</SectionHeader>
        <Paragraph>We implement industry-standard security measures to protect your personal information. However, no method of transmission or storage is 100% secure. By using our Services, you acknowledge and accept this risk.</Paragraph>

        {/* Section 8: Your Rights */}
        <SectionHeader>8. Your Rights</SectionHeader>

        {/* Subsection 8.1: Under GDPR */}
        <SubSectionHeader>8.1 Under GDPR:</SubSectionHeader>
        <OrderedList>
          <ListItem><strong>Access:</strong> Request a copy of the information we hold about you.</ListItem>
          <ListItem><strong>Correction:</strong> Update or correct your personal information.</ListItem>
          <ListItem><strong>Deletion (Right to be Forgotten):</strong> Request the deletion of your data, subject to legal obligations.</ListItem>
          <ListItem><strong>Restriction of Processing:</strong> Request the limitation of processing your data under certain conditions.</ListItem>
          <ListItem><strong>Data Portability:</strong> Receive your personal data in a structured, commonly used, and machine-readable format and have the right to transmit it to another controller.</ListItem>
          <ListItem><strong>Objection:</strong> Object to the processing of your personal data based on legitimate interests or for direct marketing purposes.</ListItem>
          <ListItem><strong>Automated Decision-Making and Profiling:</strong> Request information about automated decision-making processes and object to them if applicable.</ListItem>
        </OrderedList>

        {/* Subsection 8.2: Under CCPA */}
        <SubSectionHeader>8.2 Under CCPA:</SubSectionHeader>
        <OrderedList>
          <ListItem><strong>Right to Know:</strong> Request information about the personal data we collect, use, disclose, and sell.</ListItem>
          <ListItem><strong>Right to Delete:</strong> Request the deletion of your personal information held by us.</ListItem>
          <ListItem><strong>Right to Opt-Out:</strong> Opt-out of the sale of your personal information.</ListItem>
          <ListItem><strong>Non-Discrimination:</strong> Receive equal service and price, even if you exercise your privacy rights.</ListItem>
        </OrderedList>

        {/* How to Exercise Your Rights */}
        <SubSectionHeader>How to Exercise Your Rights:</SubSectionHeader>
        <Paragraph>To exercise any of these rights, please contact us at:</Paragraph>
        <UnorderedList>
          <ListItem><strong>Email:</strong> <Link href="mailto:hello@tempostock.com">hello@tempostock.com</Link></ListItem>
        </UnorderedList>
        <Paragraph>We will respond to your request within the time frame required by applicable laws.</Paragraph>

        {/* Section 9: Third-Party Links */}
        <SectionHeader>9. Third-Party Links</SectionHeader>
        <Paragraph>Our website may contain links to third-party websites. We are not responsible for their privacy practices or content. We encourage you to review their privacy policies.</Paragraph>

        {/* Section 10: Children’s Privacy */}
        <SectionHeader>10. Children’s Privacy</SectionHeader>
        <Paragraph>Our Services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information promptly. If you believe that we have collected personal information from a child under 13, please contact us at <Link href="mailto:hello@tempostock.com">hello@tempostock.com</Link>.</Paragraph>

        {/* Section 11: Changes to This Policy */}
        <SectionHeader>11. Changes to This Policy</SectionHeader>
        <Paragraph>We may update this Privacy Policy periodically. Changes will be effective upon posting, and continued use of our Services constitutes acceptance of the updated policy.</Paragraph>

        {/* Section 12: Contact Information */}
        <SectionHeader>12. Contact Information</SectionHeader>
        <Paragraph>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us:</Paragraph>
        <UnorderedList>
          <ListItem><strong>Email:</strong> <Link href="mailto:hello@tempostock.com">hello@tempostock.com</Link></ListItem>
          <ListItem><strong>Website:</strong> <Link href="https://tempostock.com" target="_blank" rel="noopener noreferrer">https://tempostock.com</Link></ListItem>
        </UnorderedList>
        <Paragraph>We are committed to addressing your concerns and ensuring your privacy rights are respected.</Paragraph>

        {/* Acknowledgment */}
        <SectionHeader>Acknowledgment</SectionHeader>
        <Paragraph>By using Tempo Stock, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</Paragraph>
      </PrivacyContainer>
    </>
  );
};

export default PrivacyPolicy;