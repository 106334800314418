import React from 'react';
import styled from 'styled-components';

// Styled component for the Header Section
const HeaderSection = styled.div`
  text-align: center;
  margin: 30px 0px;
  font-family: 'DM Sans', sans-serif;
  color: #2e2e2e;

  .icon {
    font-size: 50px;
    color: #2e2e2e;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 32px;
    margin: 0;
  }
`;

// TopText Component
const TopText = ({ icon: Icon, title }) => {
  return (
    <HeaderSection>
      {Icon && <Icon className="icon" />}
      <h2>{title}</h2>
    </HeaderSection>
  );
};

export default TopText;