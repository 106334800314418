// src/pages/ResetPassword.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';

const ResetPasswordContainer = styled.div`
  max-width: 400px;
  margin: 50px auto;
  padding: 40px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Rubik', sans-serif;

  @media (max-width: 480px) {
    padding: 20px;
    margin: 30px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
    font-family: 'Rubik', sans-serif;
  }

  input[type="password"] {
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    transition: border-color 0.3s;

    &:focus {
      border-color: #2e2e2e;
      outline: none;
    }
  }

  button {
    padding: 12px;
    background-color: #2e2e2e;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    transition: background-color 0.3s;

    &:hover {
      background-color: #444;
    }

    &:disabled {
      background-color: #aaa;
      cursor: not-allowed;
    }
  }
`;

const Message = styled.p`
  color: #388e3c; /* Green */
  font-size: 14px;
  margin-bottom: 15px;
  font-family: 'Rubik', sans-serif;
`;

const ErrorMessage = styled.p`
  color: #d32f2f; /* Red */
  font-size: 14px;
  margin-bottom: 15px;
  font-family: 'Rubik', sans-serif;
`;

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError('');

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, newPassword }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Password has been reset successfully. Redirecting to login...');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        setError(data.error || 'Failed to reset password.');
      }
    } catch (err) {
      setError('An error occurred. Please email us at hello@tempostock.com to reset your password.');
    } finally {
      setLoading(false);
    }
  };

  if (!token) {
    return (
      <ResetPasswordContainer>
        <h2 style={{ textAlign: 'center', fontFamily: 'Rubik, sans-serif', color: '#2e2e2e' }}>Invalid Reset Link</h2>
        <p style={{ textAlign: 'center', fontFamily: 'Rubik, sans-serif', color: '#333' }}>
          The password reset link is invalid or has expired.
        </p>
        <Link to="/forgot-password" style={{ marginTop: '20px', display: 'block', textAlign: 'center', color: '#2e2e2e', textDecoration: 'underline' }}>
          Request a new reset link
        </Link>
      </ResetPasswordContainer>
    );
  }

  return (
    <ResetPasswordContainer>
      <h2 style={{ textAlign: 'center', fontFamily: 'Rubik, sans-serif', color: '#2e2e2e' }}>Reset Password</h2>
      <Form onSubmit={handleResetPassword}>
        {message && <Message>{message}</Message>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <label htmlFor="new-password">New Password:</label>
        <input
          type="password"
          id="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          placeholder="Enter new password"
        />
        <label htmlFor="confirm-password">Confirm New Password:</label>
        <input
          type="password"
          id="confirm-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          placeholder="Confirm new password"
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Resetting...' : 'Reset Password'}
        </button>
      </Form>
    </ResetPasswordContainer>
  );
};

export default ResetPassword;