// src/utils/getSignedUrl.js
const MEDIA_WORKER_URL = process.env.REACT_APP_MEDIA_WORKER_URL || 'https://media.tempostock.com/generate-signed-url';

let signedUrlCache = {};

export const getSignedUrl = async (filePath) => {
  if (signedUrlCache[filePath]) {
    console.log(`Cache hit for filePath: ${filePath}`);
    return signedUrlCache[filePath];
  }

  try {
    console.log(`Requesting signed URL for filePath: ${filePath}`);
    const response = await fetch(MEDIA_WORKER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ filePath }),
    });

    console.log(`Media Worker response status: ${response.status}`);
    if (!response.ok) {
      const errorText = await response.text();
      console.error(`Failed to get signed URL: ${errorText}`);
      return null;
    }

    const data = await response.json();
    signedUrlCache[filePath] = data.signedUrl;
    console.log(`Received signedUrl: ${data.signedUrl}`);
    return data.signedUrl;
  } catch (error) {
    console.error('Error fetching signed URL:', error);
    return null;
  }
};