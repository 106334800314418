// src/components/Header.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #E3E3E3;
  margin: 0;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #2E2E2E; /* Deep Charcoal */

  img {
    display: block;
    height: 40px; /* Adjust as needed */
  }

  /* Text element for the logo */
  .logo-text {
    margin-left: 10px;
    font-family: 'DM Serif Text', serif;
    font-size: 28px; /* Adjust as needed */
    color: #2E2E2E;
    font-weight: 700;

    /* Hide text on mobile devices */
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const NavLinks = styled.nav`
  display: flex; /* Ensure links are in a row */
  align-items: center; /* Centers links vertically within the nav */
  margin: 0; /* Reset margin */
  padding: 0; /* Reset padding */

  & a {
    margin-left: 20px;
    text-decoration: none;
    color: #2E2E2E; /* Deep Charcoal */
    font-size: 16px; /* Ensure consistent font size */
    line-height: 1; /* Ensure consistent line-height */
    padding: 0; /* Reset padding */
  }
`;

function Header() {
  const { user } = useContext(AuthContext); // Access user from context

  return (
    <HeaderContainer>
      <LogoLink to="/">
        {/* Icon logo */}
        <img src="/images/logo/logo_iconmark.png" alt="Tempo Stock Logo" />
        {/* Text logo, hidden on mobile */}
        <span className="logo-text">tempo stock</span>
      </LogoLink>
      <NavLinks>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        {user ? (
          <Link to="/user">My Account</Link>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </NavLinks>
    </HeaderContainer>
  );
}

export default Header;