import React from 'react';
import styled from 'styled-components';
import TopText from '../components/TopText'; // Import the TopText component
import { FaFileContract } from 'react-icons/fa'; // Import the contract icon

// Shared Text Color
const textColor = '#555';

// Styled Components
const TOSContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin: 40px 20px; /* Top & Bottom: 40px, Left & Right: 20px */
  }
`;

const SectionTitle = styled.p`
  font-family: 'Rubik', sans-serif;
  color: ${textColor}; /* Updated from #6A6A6A to #555 */
  margin: 30px 0 15px;
  text-align: center;
  font-size: 1em;
`;

const SectionHeader = styled.h2`
  font-family: 'DM Serif Text', serif;
  color: ${textColor}; /* Updated from #333 to #555 */
  margin: 30px 0 15px;
  text-align: left;
  font-size: 1.5em;
`;

const SubSectionHeader = styled.h3`
  font-family: 'Rubik', sans-serif;
  color: ${textColor}; /* Updated from #333 to #555 */
  margin: 20px 0 10px;
  font-size: 1em;
`;

const Paragraph = styled.p`
  font-family: 'Rubik', sans-serif;
  color: ${textColor}; /* Ensures consistency */
  line-height: 1.6;
  margin-bottom: 15px;
`;

const UnorderedList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
`;

const OrderedList = styled.ol`
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 15px;
`;

const ListItem = styled.li`
  color: ${textColor}; /* Ensure list items match paragraph text */
  margin-bottom: 10px;
`;

const Link = styled.a`
  color: #1a0dab;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

// Terms of Service Component
const TOS = () => {
  return (
    <>
      {/* Header Section with Icon and Title using TopText */}
      <TopText icon={FaFileContract} title="Terms of Service" />
      <SectionTitle>Effective Date: December 10, 2024</SectionTitle>

      <TOSContainer>
        <Paragraph>
          Welcome to Tempo Stock! By accessing or using our website, services, or products (collectively referred to as the “Services”), you agree to comply with and be bound by these Terms of Service (“Terms”). Please read them carefully.
        </Paragraph>
        <UnorderedList>
          <ListItem><strong>"Content”</strong> refers to AI-generated, royalty-free music provided by Tempo Stock.</ListItem>
          <ListItem><strong>"User”</strong> refers to any individual or entity accessing or using Tempo Stock's Services.</ListItem>
          <ListItem><strong>"License”</strong> refers to the limited rights granted to Users to use the Content as specified in these Terms.</ListItem>
          <ListItem><strong>"Services”</strong> encompass the website, products, and any other offerings provided by Tempo Stock.</ListItem>
        </UnorderedList>

        {/* Section 1 */}
        <SectionHeader>1. Acceptance of Terms</SectionHeader>
        <Paragraph>
          By using Tempo Stock (“we,” “our,” “us”), you agree to these Terms and our Privacy Policy. If you do not agree, you may not use our Services.
        </Paragraph>

        {/* Section 2 */}
        <SectionHeader>2. Description of Services</SectionHeader>
        <Paragraph>
          Tempo Stock provides access to high-quality, AI-generated, royalty-free music (“Content”) for use in video projects and other creative works.
        </Paragraph>
        <SubSectionHeader>Key Features:</SubSectionHeader>
        <UnorderedList>
          <ListItem>Content is AI-generated and curated for professional quality.</ListItem>
          <ListItem>Users pay for the right to download and use the Content, not for ownership or royalties.</ListItem>
        </UnorderedList>

        {/* Section 3 */}
        <SectionHeader>3. User Responsibilities</SectionHeader>
        <Paragraph>By using our Services, you agree to:</Paragraph>
        <OrderedList>
          <ListItem><strong>Comply with copyright law</strong>: You cannot use Tempo Stock’s Content in ways that violate applicable copyright laws.</ListItem>
          <ListItem><strong>Avoid resale or redistribution</strong>: You may not resell, redistribute, or sublicense the Content downloaded from Tempo Stock.</ListItem>
          <ListItem><strong>Limit liability</strong>: Tempo Stock cannot be held liable for any claims, damages, or legal disputes arising from your use of the Content.</ListItem>
        </OrderedList>

        {/* Section 4 */}
        <SectionHeader>4. License to Use Content</SectionHeader>
        <Paragraph>
          When you download Content from Tempo Stock, you are granted a limited, non-exclusive, non-transferable license to use the Content in your creative projects.
        </Paragraph>
        <SubSectionHeader>Permissible Uses:</SubSectionHeader>
        <OrderedList>
          <ListItem><strong>Integration into projects:</strong> Content can be used in video projects, including but not limited to:
            <UnorderedList>
              <ListItem>Social media videos, user-generated content (UGC), and personal projects.</ListItem>
              <ListItem>Commercial video projects, advertisements, or branded content.</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem><strong>Sharing completed works:</strong> Content may be shared with your friends, collaborators, and community as part of your completed video projects.</ListItem>
          <ListItem><strong>Modification:</strong> Users may modify the Content as necessary for their projects, provided the modifications do not infringe upon the prohibited uses outlined below.</ListItem>
        </OrderedList>
        <SubSectionHeader>Prohibited Uses:</SubSectionHeader>
        <OrderedList>
          <ListItem><strong>Resale and redistribution:</strong> You may not resell, redistribute, or sublicense the raw Content as standalone files.</ListItem>
          <ListItem><strong>Ownership claims:</strong> You may not claim ownership of the Content.</ListItem>
          <ListItem><strong>Rights registration:</strong> You may not register the Content with any performing rights organizations, content identification systems (e.g., YouTube Content ID), or copyright registries.</ListItem>
          <ListItem><strong>Royalty collection:</strong> You may not collect royalties on the Content from any third-party platforms.</ListItem>
          <ListItem><strong>Commercial redistribution:</strong> Redistribution of modified Content for commercial purposes without explicit permission is prohibited.</ListItem>
        </OrderedList>

        <SubSectionHeader>Attribution Requirements:</SubSectionHeader>
        <Paragraph>
          Users on the Free tier of Tempo Stock must credit Tempo Stock in their projects. Appropriate credit can be provided in the project description, credits section, or wherever applicable. Pro and Unlimited tier users have different attribution requirements, which will be determined in future updates.
        </Paragraph>

        {/* Section 5 */}
        <SectionHeader>5. Payment and Subscription</SectionHeader>
        <Paragraph>
          Access to Tempo Stock Content is provided on a subscription or one-time payment basis.
        </Paragraph>

        <SubSectionHeader>Subscription Terms:</SubSectionHeader>
        <OrderedList>
          <ListItem><strong>Billing Cycle:</strong> Subscriptions are billed on a monthly or annual basis, depending on the chosen plan.</ListItem>
          <ListItem><strong>Automatic Renewal:</strong> Unless canceled at least 24 hours before the end of the current period, subscriptions will automatically renew for the same duration and fee.</ListItem>
          <ListItem><strong>Cancellation:</strong> You may cancel your subscription at any time through your account settings. Cancellation will take effect at the end of the current billing cycle.</ListItem>
          <ListItem><strong>Refunds:</strong> All fees are non-refundable, except as required by law.</ListItem>
        </OrderedList>

        <SubSectionHeader>Pricing Changes:</SubSectionHeader>
        <Paragraph>
          We reserve the right to change our pricing at any time. We will notify you of any significant changes at least 30 days before they take effect. Continued use of our Services after the pricing change constitutes your acceptance of the new prices.
        </Paragraph>

        {/* Section 6 */}
        <SectionHeader>6. Intellectual Property</SectionHeader>
        <Paragraph>
          All Content provided by Tempo Stock is generated by artificial intelligence and curated by our team. While AI-generated works may not be eligible for traditional copyright protection under current laws, the rights to download and use the Content remain protected under these Terms.
        </Paragraph>

        <SubSectionHeader>User-Generated Content:</SubSectionHeader>
        <Paragraph>
          Any content created by users using Tempo Stock's Services remains the intellectual property of the user, provided it complies with these Terms. By using our Services, you grant Tempo Stock a non-exclusive, worldwide, royalty-free license to use, reproduce, and display your content for the purpose of operating and improving our Services.
        </Paragraph>

        {/* Section 7 */}
        <SectionHeader>7. Liability and Indemnity</SectionHeader>
        <OrderedList>
          <ListItem>Tempo Stock is not responsible for any damages, losses, or legal disputes arising from the use of its Content.</ListItem>
          <ListItem>By using our Services, you agree to indemnify and hold Tempo Stock harmless from any claims, liabilities, damages, or expenses (including legal fees) resulting from your use of the Content.</ListItem>
        </OrderedList>

        {/* Section 8 */}
        <SectionHeader>8. Disclaimers</SectionHeader>
        <OrderedList>
          <ListItem><strong>No guarantees:</strong> Tempo Stock does not guarantee the suitability of its Content for any specific use.</ListItem>
          <ListItem><strong>User compliance:</strong> You are solely responsible for ensuring your use of the Content complies with applicable laws and regulations.</ListItem>
          <ListItem><strong>AI limitations:</strong> As the Content is AI-generated, Tempo Stock does not claim copyright ownership, but users must still adhere to these Terms.</ListItem>
        </OrderedList>

        {/* Section 9 */}
        <SectionHeader>9. Changes to Terms</SectionHeader>
        <Paragraph>
          We may modify these Terms at any time. Changes will be effective upon posting. Continued use of our Services after changes are made constitutes your acceptance of the updated Terms.
        </Paragraph>

        {/* Section 10 */}
        <SectionHeader>10. Termination</SectionHeader>
        <Paragraph>
          We reserve the right to terminate or suspend your access to Tempo Stock Services immediately, without prior notice or liability, for any reason, including but not limited to:
        </Paragraph>
        <OrderedList>
          <ListItem>Breach of these Terms.</ListItem>
          <ListItem>Engaging in fraudulent or illegal activities.</ListItem>
          <ListItem>Violating the rights of other users or third parties.</ListItem>
        </OrderedList>
        <Paragraph>
          Upon termination, your right to use the Content will immediately cease, and you must destroy any downloaded Content in your possession.
        </Paragraph>

        {/* Section 11 */}
        <SectionHeader>11. Dispute Resolution</SectionHeader>
        <SubSectionHeader>Arbitration Agreement:</SubSectionHeader>
        <Paragraph>
          Any disputes arising out of or related to these Terms or the use of Tempo Stock Services shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.
        </Paragraph>
        <SubSectionHeader>Waiver of Class Actions:</SubSectionHeader>
        <Paragraph>
          By agreeing to these Terms, you waive your right to participate in a class action lawsuit or class-wide arbitration against Tempo Stock.
        </Paragraph>
        <SubSectionHeader>Governing Law:</SubSectionHeader>
        <Paragraph>
          These Terms shall be governed by and construed in accordance with the laws of the state of Tennessee, USA, without regard to its conflict of law principles.
        </Paragraph>

        {/* Section 12 */}
        <SectionHeader>12. Severability</SectionHeader>
        <Paragraph>
          If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions will remain in full force and effect.
        </Paragraph>

        {/* Section 13 */}
        <SectionHeader>13. Entire Agreement</SectionHeader>
        <Paragraph>
          These Terms constitute the entire agreement between you and Tempo Stock regarding the use of our Services and supersede all prior agreements, understandings, and communications.
        </Paragraph>

        {/* Section 14 */}
        <SectionHeader>14. No Waiver</SectionHeader>
        <Paragraph>
          No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Tempo Stock's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.
        </Paragraph>

        {/* Section 15 */}
        <SectionHeader>15. Privacy Policy</SectionHeader>
        <Paragraph>
          Your use of Tempo Stock Services is also governed by our Privacy Policy, which can be accessed <Link href="https://tempostock.com/privacy" target="_blank" rel="noopener noreferrer">here</Link>. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.
        </Paragraph>

        {/* Section 16 */}
        <SectionHeader>16. Force Majeure</SectionHeader>
        <Paragraph>
          Tempo Stock shall not be liable for any failure to perform its obligations under these Terms if such failure results from any cause beyond Tempo Stock’s reasonable control, including but not limited to acts of God, war, terrorism, labor disputes, or governmental regulations.
        </Paragraph>

        {/* Section 17 */}
        <SectionHeader>17. Contact Information</SectionHeader>
        <Paragraph>
          For questions or concerns regarding these Terms, please contact us at:
        </Paragraph>
        <UnorderedList>
          <ListItem><strong>Email:</strong> <Link href="mailto:hello@tempostock.com">hello@tempostock.com</Link></ListItem>
          <ListItem><strong>Website:</strong> <Link href="https://tempostock.com" target="_blank" rel="noopener noreferrer">https://tempostock.com</Link></ListItem>
        </UnorderedList>

        {/* Acknowledgment */}
        <SectionHeader>Acknowledgment</SectionHeader>
        <Paragraph>
          By using Tempo Stock, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service and our Privacy Policy.
        </Paragraph>
      </TOSContainer>
    </>
  );
};

export default TOS;