// src/styles/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Rubik', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #E3E3E3; /* Pale Stone Gray */
    color: #2E2E2E; /* Deep Charcoal */
  }
  h1, h2, h3 {
    font-family: 'DM Serif Text', serif;
    font-weight: 700;
  }
  /* Global Link Styles */
  a {
    color: #6E6E6E; /* Soft Gray */
    font-weight: bold;
    text-decoration: none; /* Remove underline */
    transition: color 0.3s;

    &:hover {
      color: #4A4A4A; /* Darker Gray on Hover */
    }
  }

  a:visited {
    color: #6E6E6E; /* Maintain Soft Gray for visited links */
  }
`;

export default GlobalStyles;