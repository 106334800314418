// src/components/CategorySection.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SectionContainer = styled.div`
  text-align: center;
  margin: 50px 0;
`;

const BlocksContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const CategoryBlock = styled(Link)`
  width: 300px;
  height: 300px;
  margin: 15px;
  background-image: url(${(props) => props.$bgImage});
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  text-decoration: none;
  color: white;
  font-size: 22px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  /* Soft Shader Overlay */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    transition: backdrop-filter 0.3s ease, background 0.3s ease;
  }

  /* Enhance on Hover */
  &:hover::after {
    backdrop-filter: blur(15px);
    background: rgba(0, 0, 0, 0.4);
  }

  /* Text Styling */
  span {
    position: relative;
    z-index: 1;
    color: #FFFFFF;
    font-weight: bold;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  /* Responsive Adjustments */
  @media (max-width: 768px) {
    width: 250px;
    height: 250px;
    font-size: 20px;
  }

  @media (max-width: 480px) {
    width: 90vw;
    height: 90vw;
    font-size: 18px;
  }
`;

function CategorySection() {
  const categories = [
    { name: 'Corporate', image: '/images/categories/corporate.jpg' },
    { name: 'Cinematic', image: '/images/categories/cinematic.jpg' },
    { name: 'Beats', image: '/images/categories/pop.jpg' },
    { name: 'Ambient', image: '/images/categories/ambient.jpg' },
    { name: 'Lofi', image: '/images/categories/lofi.jpg' },
  ];

  return (
    <SectionContainer>
      <h2>Categories</h2>
      <BlocksContainer>
        {categories.map((category) => (
          <CategoryBlock
            to={`/${category.name.toLowerCase()}`}
            key={category.name}
            $bgImage={category.image} // Using transient prop
          >
            <span>{category.name}</span>
          </CategoryBlock>
        ))}
      </BlocksContainer>
    </SectionContainer>
  );
}

export default CategorySection;