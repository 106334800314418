import React, { createContext, useState, useContext } from 'react';

const SongContext = createContext();

export const useSongContext = () => useContext(SongContext);

export const SongProvider = ({ children }) => {
    const [currentSongId, setCurrentSongId] = useState(null);

    return (
        <SongContext.Provider value={{ currentSongId, setCurrentSongId }}>
            {children}
        </SongContext.Provider>
    );
};