import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SongCard from '../components/SongCard';
import { getAllSongs } from '../utils/songUtils';

const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E3E3; /* Pale Stone Gray */
`;

const Title = styled.h1`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  margin-bottom: 20px;
  text-align: center;
`;

const SongGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
`;

function FeaturedPage() {
  const [featuredSongs, setFeaturedSongs] = useState([]);

  useEffect(() => {
    const fetchFeaturedSongs = async () => {
      const allSongs = await getAllSongs();
      const featured = allSongs.filter((song) => song.song_featured);
      setFeaturedSongs(featured);
    };
    fetchFeaturedSongs();
  }, []);

  return (
    <PageContainer>
      <Title>Featured Songs</Title>
      <SongGrid>
        {featuredSongs.map((song) => (
          <SongCard key={song.song_id} song={song} />
        ))}
      </SongGrid>
    </PageContainer>
  );
}

export default FeaturedPage;