// src/pages/Login.js
import React, { useState, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { AuthContext } from '../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';

// Global styles to set box-sizing
const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
`;

// Updated Tabs with sliding underline
const Tabs = styled.div`
  position: relative; /* To position the underline absolutely within Tabs */
  display: flex;
  margin-bottom: 35px;

  button {
    flex: 1;
    padding: 14px 0;
    background: none;
    border: none;
    border-bottom: none; /* Remove existing border */
    font-size: 18px;
    cursor: pointer;
    font-family: 'Rubik', sans-serif;
    transition: color 0.3s;

    &.active {
      font-weight: bold;
      color: #2e2e2e;
    }

    &:hover:not(.active) {
      color: #555;
    }
  }

  /* Sliding underline */
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 50%; /* Since there are two tabs */
    background-color: #2e2e2e;
    transform: translateX(${props => (props.activeTab === 'login' ? '0%' : '100%')});
    transition: transform 0.3s ease;
  }
`;

// Form container with increased gap for input fields
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// Updated InputContainer with increased bottom margin
const InputContainer = styled.div`
  position: relative;
  width: 100%; /* Ensure input container takes full width */
  margin-bottom: 10px; /* Increased bottom margin */

  input {
    width: 100%;
    padding: 14px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    transition: border-color 0.3s;
    background: none;

    &:focus {
      border-color: #2e2e2e;
      outline: none;
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
      top: -8px;
      left: 10px;
      font-size: 12px;
      color: #2e2e2e;
      background-color: white;
      padding: 0 4px;
    }
  }

  label {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    color: #999;
    font-size: 16px;
    pointer-events: none;
    transition: all 0.2s ease-out;
  }
`;

// Updated CheckboxGroup with reduced margin
const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Reduced margin from 25px to 10px */

  input {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }

  label {
    font-size: 14px;
    color: #333;
  }

  a {
    color: #2e2e2e;
    text-decoration: underline;

    &:hover {
      color: #555;
    }
  }
`;

const LoginContainer = styled.div`
  max-width: 500px;
  width: 90%;
  margin: 50px auto;
  padding: 40px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Rubik', sans-serif;

  @media (max-width: 480px) {
    padding: 25px;
    margin: 30px 10px; /* Added horizontal margin for small screens */
  }
`;

const Button = styled.button`
  width: 100%; /* Ensure buttons span the full width */
  padding: 14px;
  background-color: #2e2e2e;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  transition: background-color 0.3s;

  &:hover {
    background-color: #444;
  }

  &:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
`;

const ComingSoonButton = styled(Button)`
  background-color: #db4437;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  cursor: not-allowed; /* Indicate disabled state */

  &:hover {
    background-color: #c33d2e;
  }

  svg {
    margin-right: 8px;
  }
`;

const ErrorMessage = styled.p`
  color: #d32f2f; /* Red */
  font-size: 14px;
  margin-bottom: 20px;
  font-family: 'Rubik', sans-serif;
`;

const SuccessMessage = styled.p`
  color: #388e3c; /* Green */
  font-size: 14px;
  margin-bottom: 20px;
  font-family: 'Rubik', sans-serif;
`;

const ForgotPasswordLink = styled(Link)`
  margin-top: 20px;
  text-align: center;
  display: block;
  font-size: 14px;
  color: #2e2e2e;
  text-decoration: underline;

  &:hover {
    color: #555;
  }
`;

const PasswordRequirements = styled.div`
  margin-top: -10px;
  font-size: 12px;
  color: #666;
`;

const Login = () => {
  const { login, signup } = useContext(AuthContext);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('login'); // 'login' or 'signup'
  const [disableAuth] = useState(false); // Toggle 'true' to disable signups

  // Common states
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Signup specific
  const [agreeToTOS, setAgreeToTOS] = useState(false);
  const [agreeToPrivacy, setAgreeToPrivacy] = useState(false);

  // Error handling
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Helper functions for validation
  const isValidEmail = (email) => {
    const allowedBase = 'com.jake@gmail.com';
    const emailLower = email.toLowerCase();
    
    // Check if the email exactly matches the allowed base
    if (emailLower === allowedBase) {
      return true;
    }
    
    // Allow emails that start with 'com.jake+' and end with '@gmail.com'
    const testingEmailRegex = /^com\.jake\+[^@]+@gmail\.com$/;
    
    if (testingEmailRegex.test(emailLower)) {
      return true;
    }
    
    // For all other emails, disallow '+'
    return !/\+/.test(email);
  };

  const isValidPassword = (password) => {
    // Password must be at least 8 characters long and include uppercase, lowercase, number, and special character
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()\-_=+{};:,<.>]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Optional: Enforce password standards on login (if desired)
    if (!isValidPassword(password)) {
      setError('Password does not meet the required standards.');
      return;
    }

    const result = await login(email, password);
    if (result.success) {
      navigate('/');
    } else {
      setError(result.message || 'Login failed.');
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Email validation
    if (!isValidEmail(email)) {
      setError('Emails with "+" are not allowed.');
      return;
    }

    // Password validation
    if (!isValidPassword(password)) {
      setError(
        'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.'
      );
      return;
    }

    // Agreement validation
    if (!agreeToTOS || !agreeToPrivacy) {
      setError('You must agree to the Terms of Service and Privacy Policy.');
      return;
    }

    const result = await signup(email, password, agreeToTOS, agreeToPrivacy);
    if (result.success) {
      setSuccess('Signup successful! Redirecting...');
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } else {
      setError(result.message || 'Signup failed.');
    }
  };

  return (
    <>
      <GlobalStyle />
      <LoginContainer>
        <Tabs activeTab={activeTab}>
          <button
            className={activeTab === 'login' ? 'active' : ''}
            onClick={() => setActiveTab('login')}
            disabled={disableAuth}
          >
            Login
          </button>
          <button
            className={activeTab === 'signup' ? 'active' : ''}
            onClick={() => setActiveTab('signup')}
            disabled={disableAuth}
          >
            Sign Up
          </button>
        </Tabs>

        {disableAuth ? (
          <ErrorMessage>
            Login and Sign Up are currently disabled. Please check back later.
          </ErrorMessage>
        ) : activeTab === 'login' ? (
          <Form onSubmit={handleLogin}>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {success && <SuccessMessage>{success}</SuccessMessage>}

            <InputContainer>
              <input
                type="email"
                id="login-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder=" "
              />
              <label htmlFor="login-email">Enter your email</label>
            </InputContainer>

            <InputContainer>
              <input
                type="password"
                id="login-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder=" "
              />
              <label htmlFor="login-password">Enter your password</label>
            </InputContainer>

            <Button type="submit">Login</Button>
            <ComingSoonButton type="button" disabled title="Google OAuth Coming Soon">
              <FaGoogle /> Coming Soon
            </ComingSoonButton>
            <ForgotPasswordLink to="/forgot-password">
              Forgot Password?
            </ForgotPasswordLink>
          </Form>
        ) : (
          <Form onSubmit={handleSignup}>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {success && <SuccessMessage>{success}</SuccessMessage>}

            <InputContainer>
              <input
                type="email"
                id="signup-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder=" "
              />
              <label htmlFor="signup-email">Enter your email</label>
            </InputContainer>

            <InputContainer>
              <input
                type="password"
                id="signup-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder=" "
              />
              <label htmlFor="signup-password">Create a password</label>
            </InputContainer>
            {/* Moved PasswordRequirements outside InputContainer */}
            <PasswordRequirements>
              <small>
                Password must be at least 8 characters and include uppercase, lowercase, number, and special character.
              </small>
            </PasswordRequirements>

            <CheckboxGroup>
              <input
                type="checkbox"
                id="agree-tos"
                checked={agreeToTOS}
                onChange={(e) => setAgreeToTOS(e.target.checked)}
              />
              <label htmlFor="agree-tos">
                I agree to the <Link to="/tos">Terms of Service</Link>
              </label>
            </CheckboxGroup>
            <CheckboxGroup>
              <input
                type="checkbox"
                id="agree-privacy"
                checked={agreeToPrivacy}
                onChange={(e) => setAgreeToPrivacy(e.target.checked)}
              />
              <label htmlFor="agree-privacy">
                I agree to the <Link to="/privacy">Privacy Policy</Link>
              </label>
            </CheckboxGroup>

            <Button type="submit">Sign Up</Button>
            <ComingSoonButton type="button" disabled title="Google OAuth Coming Soon">
              <FaGoogle /> Coming Soon
            </ComingSoonButton>
          </Form>
        )}
      </LoginContainer>
    </>
  );
};

export default Login;