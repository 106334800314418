// src/pages/CategoryPage.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import SongCard from '../components/SongCard'; // Ensure correct path
import { getAllSongs } from '../utils/songUtils';
import { 
  FaWater, FaStar, FaBriefcase, FaSun, FaFilm, FaLeaf, FaMusic, FaPeace, FaBolt, 
  FaMountain, FaGuitar, FaSadTear, FaCoffee, FaHeart, FaSmile, FaCompactDisc, 
  FaCloud, FaMicrochip, FaFire, FaMicrophone, FaDrum, FaQuestionCircle, FaGift 
} from 'react-icons/fa';
import { GiAccordion, GiClover, GiMusicalNotes, GiTrumpet, GiMeditation } from 'react-icons/gi';
import TopText from '../components/TopText'; 

// Styled Components (unchanged)
const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E3E3; /* Pale Stone Gray */
`;

const FeaturedHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -5px;
  padding: 5px 10px;
  border: 1px solid #DAA520; /* Goldenrod Outline */
  border-radius: 20px;
  color: #DAA520; /* Goldenrod Text */
  font-family: 'Rubik', sans-serif;
  font-size: 0.9em;

  svg {
    margin-right: 5px;
  }

  margin-top: 30px;
`;

const SectionTitle = styled.p`
  font-family: 'Rubik', sans-serif;
  color: #6A6A6A;
  margin: 30px 0 15px;
  text-align: center;
  font-size: 1em;
`;

const SongGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 20px auto 0;
  justify-items: center;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const EmptyMessage = styled.p`
  font-family: 'Rubik', sans-serif;
  color: #6A7D8B; /* Muted Slate Blue */
  font-size: 18px;
  margin-top: 30px;
  text-align: center;
`;

const Sentinel = styled.div`
  height: 1px;
`;

const categoryIcons = {
  Corporate: FaBriefcase,
  Uplifting: FaSun,
  Cinematic: FaFilm,
  Calm: FaLeaf,
  Piano: FaMusic,
  Peaceful: FaPeace,
  Energetic: FaBolt,
  Epic: FaMountain,
  Guitar: FaGuitar,
  Sad: FaSadTear,
  Lofi: FaCoffee,
  Romantic: FaHeart,
  Happy: FaSmile,
  Pop: FaCompactDisc,
  Vaporwave: FaCloud,
  Electronic: FaMicrochip,
  Angry: FaFire,
  'Hip-Hop': FaMicrophone,
  Beats: FaDrum,
  Mysterious: FaQuestionCircle,
  Accordion: GiAccordion,
  Celtic: GiClover,
  Classical: GiMusicalNotes,
  Holiday: FaGift,
  Trumpet: GiTrumpet,
  Meditation: GiMeditation,
  Ambient: FaWater,
};

function CategoryPage() {
  const { category } = useParams();
  const [allSongs, setAllSongs] = useState([]);
  const [featuredSongs, setFeaturedSongs] = useState([]);
  const [visibleSongs, setVisibleSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const observer = useRef();

  const songsPerLoad = 8;

  const capitalizedCategory = category.charAt(0).toUpperCase() + category.slice(1);
  const IconComponent = categoryIcons[capitalizedCategory];

  useEffect(() => {
    const fetchSongs = async () => {
      try {
        setLoading(true);
        setError(null);
        const all = await getAllSongs();
        const filteredSongs = all.filter((song) =>
          song.song_tags.includes(capitalizedCategory)
        );
        setAllSongs(filteredSongs);

        const featured = filteredSongs.filter((song) => song.song_featured);
        setFeaturedSongs(featured);

        // Initialize visible songs with the first 8 "all tracks"
        setVisibleSongs(filteredSongs.slice(0, songsPerLoad));
      } catch (err) {
        console.error('Error fetching songs:', err);
        setError('Failed to fetch songs. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchSongs();
  }, [category, capitalizedCategory]);

  const loadMoreSongs = useCallback(() => {
    setVisibleSongs((prevSongs) => {
      const nextSongs = allSongs.slice(
        prevSongs.length,
        prevSongs.length + songsPerLoad
      );
      return [...prevSongs, ...nextSongs];
    });
  }, [allSongs]);

  const sentinelRef = useRef();

  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        visibleSongs.length < allSongs.length
      ) {
        loadMoreSongs();
      }
    });

    if (sentinelRef.current) {
      observer.current.observe(sentinelRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, visibleSongs, allSongs, loadMoreSongs]);

  return (
    <PageContainer>
      {IconComponent && <TopText icon={IconComponent} title={capitalizedCategory} />}

      {loading && <EmptyMessage>Loading songs...</EmptyMessage>}
      {error && <EmptyMessage>{error}</EmptyMessage>}

      {!loading && !error && (
        <>
          {featuredSongs.length > 0 && (
            <>
              <FeaturedHeader>
                <FaStar />
                Featured Tracks
              </FeaturedHeader>
              <SongGrid>
                {featuredSongs.map((song) => (
                  <SongCard key={song.song_id} song={song} />
                ))}
              </SongGrid>
            </>
          )}

          {allSongs.length > 0 ? (
            <>
              <SectionTitle>All Tracks</SectionTitle>
              <SongGrid>
                {visibleSongs.map((song) => (
                  <SongCard key={song.song_id} song={song} />
                ))}
              </SongGrid>
              {visibleSongs.length < allSongs.length && (
                <>
                  <Sentinel ref={sentinelRef} />
                  <EmptyMessage>Loading more songs...</EmptyMessage>
                </>
              )}
            </>
          ) : (
            <EmptyMessage>No songs found in this category.</EmptyMessage>
          )}
        </>
      )}
    </PageContainer>
  );
}

export default CategoryPage;