// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

// Create Context
export const AuthContext = createContext();

// Provider Component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // User object
  const [loading, setLoading] = useState(true); // Loading state

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // On component mount, check for existing token
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Fetch user data
      fetch(`${API_BASE_URL}/auth/user`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(async (res) => {
          if (res.ok) {
            const data = await res.json();
            setUser(data);
          } else {
            // Invalid token
            localStorage.removeItem('token');
            setUser(null);
          }
        })
        .catch(() => {
          setUser(null);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [API_BASE_URL]);

  // Login function
  const login = async (email, password) => {
    try {
      const res = await fetch(`${API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      const data = await res.json();

      if (res.ok) {
        localStorage.setItem('token', data.token);
        // Fetch user data
        const userRes = await fetch(`${API_BASE_URL}/auth/user`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${data.token}` },
        });
        if (userRes.ok) {
          const userData = await userRes.json();
          setUser(userData);
          return { success: true };
        }
      }
      return { success: false, message: data.error || 'Login failed.' };
    } catch (error) {
      return { success: false, message: 'An error occurred during login.' };
    }
  };

  // Signup function
  const signup = async (email, password, agreeToTOS, agreeToPrivacy) => {
    try {
      const res = await fetch(`${API_BASE_URL}/auth/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, agreeToTOS, agreeToPrivacy }),
      });

      const data = await res.json();

      if (res.ok) {
        localStorage.setItem('token', data.token);
        // Fetch user data
        const userRes = await fetch(`${API_BASE_URL}/auth/user`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${data.token}` },
        });
        if (userRes.ok) {
          const userData = await userRes.json();
          setUser(userData);
          return { success: true };
        }
      }
      return { success: false, message: data.error || 'Signup failed.' };
    } catch (error) {
      return { success: false, message: 'An error occurred during signup.' };
    }
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

// Request Password Reset
const requestResetPassword = async (email) => {
    try {
      const res = await fetch(`${API_BASE_URL}/auth/request-reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await res.json();

      if (res.ok) {
        return { success: true, message: data.message || 'Reset link sent.' };
      }
      return { success: false, message: data.error || 'Request failed.' };
    } catch (error) {
      return { success: false, message: 'An error occurred.' };
    }
  };

  // Reset Password
  const resetPassword = async (token, newPassword) => {
    try {
      const res = await fetch(`${API_BASE_URL}/auth/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, newPassword }),
      });

      const data = await res.json();

      if (res.ok) {
        return { success: true, message: data.message || 'Password reset successful.' };
      }
      return { success: false, message: data.error || 'Reset failed.' };
    } catch (error) {
      return { success: false, message: 'An error occurred.' };
    }
  };

  // Get Download Status
  const getDownloadStatus = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return { canDownload: false };
    }

    try {
      const res = await fetch(`${API_BASE_URL}/auth/download-status`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (res.ok) {
        const data = await res.json();
        return { canDownload: data.canDownload };
      } else {
        return { canDownload: false };
      }
    } catch (error) {
      console.error('Error fetching download status:', error);
      return { canDownload: false };
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, signup, logout, requestResetPassword, resetPassword, getDownloadStatus }}>
      {children}
    </AuthContext.Provider>
  );
};