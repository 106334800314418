// src/components/SongCard.js

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FaPlay, FaPause } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useSongContext } from '../SongContext';
import { getSignedUrl } from '../utils/getSignedUrl';

// Styled Components (unchanged)
const CardLink = styled(Link)`
  display: block;
  width: 250px;
  height: 350px;
  margin: 15px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  background-image: url(${(props) => props.$bgImage});
  background-size: cover;
  background-position: center;
  color: white;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(46, 46, 46, 0.4);
    z-index: 0;
  }
`;

const RankingNumber = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(195, 166, 177, 0.8);
  color: #2E2E2E;
  padding: 5px 10px;
  border-radius: 50%;
  font-weight: bold;
  z-index: 3;
`;

const PlayButton = styled.button`
  position: absolute;
  bottom: 140px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(46, 46, 46, 0.6);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px; 
  cursor: pointer;
  color: white;
  font-size: 18px;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressBar = styled.div`
  position: absolute;
  bottom: 120px;
  left: 10px;
  right: 10px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2.5px;
  overflow: hidden;
  z-index: 2;

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: ${(props) => props.$progress}%;
    background: white;
    transition: width 0.1s linear;
  }
`;

const InfoOverlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px; 
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin: 5px 0;
    color: white;
    text-align: center;
    max-width: 90%;
    font-size: 1.1em;
  }
`;

const BpmKeyContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 5px;
`;

const BpmKeyPill = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 8px;
  color: #D8D8D8;
  font-size: 0.8em;
`;

function SongCard({ song, ranking }) {
    const { currentSongId, setCurrentSongId } = useSongContext();
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [audioSrc, setAudioSrc] = useState('');
    const [bgImageSrc, setBgImageSrc] = useState('');
    const audioRef = useRef(null);
    const cardRef = useRef();
  
    // State to manage visibility for lazy loading images
    const [isVisible, setIsVisible] = useState(false);
  
    // Fetch the background image only when the card is visible
    useEffect(() => {
      if (isVisible && !bgImageSrc) {
        const fetchArtUrl = async () => {
          const signedBgImageUrl = await getSignedUrl(song.song_art);
          if (signedBgImageUrl) {
            setBgImageSrc(signedBgImageUrl);
          }
        };
        fetchArtUrl();
      }
    }, [isVisible, bgImageSrc, song.song_art]);
  
    // Setup IntersectionObserver for the card
    useEffect(() => {
      const currentRef = cardRef.current; // Capture the current ref
  
      if (!currentRef) return; // If ref is not set, do nothing
  
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.unobserve(entry.target);
            }
          });
        },
        {
          threshold: 0.1,
        }
      );
  
      observer.observe(currentRef); // Observe the captured ref
  
      return () => {
        observer.unobserve(currentRef); // Cleanup using the captured ref
      };
    }, []); // Empty dependency array ensures this runs once
  
    const togglePlay = async (e) => {
      e.preventDefault();
  
      if (currentSongId !== song.song_id) {
        setCurrentSongId(song.song_id);
      }
  
      // If we don't have an audio source yet, fetch it now
      if (!audioSrc) {
        const signedAudioUrl = await getSignedUrl(song.song_file_wm);
        if (signedAudioUrl) {
          setAudioSrc(signedAudioUrl);
          // After setting the audioSrc, the audio element will update. We need
          // to wait for the state update before playing.
          setTimeout(() => {
            if (audioRef.current) {
              audioRef.current.play();
              setIsPlaying(true);
            }
          }, 0);
        }
      } else {
        // Audio source is available, just play/pause
        if (!isPlaying && audioRef.current) {
          audioRef.current.play();
        } else if (audioRef.current) {
          audioRef.current.pause();
        }
  
        setIsPlaying(!isPlaying);
      }
    };
  
    useEffect(() => {
      const audio = audioRef.current;
      if (!audio) return;
  
      const updateProgress = () => {
        if (audio.duration) {
          setProgress((audio.currentTime / audio.duration) * 100);
        }
      };
  
      const handleEnd = () => {
        setIsPlaying(false);
        setProgress(0);
      };
  
      audio.addEventListener('timeupdate', updateProgress);
      audio.addEventListener('ended', handleEnd);
  
      return () => {
        audio.removeEventListener('timeupdate', updateProgress);
        audio.removeEventListener('ended', handleEnd);
      };
    }, [audioSrc]);
  
    useEffect(() => {
      if (currentSongId !== song.song_id && isPlaying) {
        if (audioRef.current) {
          audioRef.current.pause();
        }
        setIsPlaying(false);
        setProgress(0);
      }
    }, [currentSongId, isPlaying, song.song_id]);
  
    return (
      <CardLink to={`/songs/${song.song_id}`} $bgImage={bgImageSrc || ''} ref={cardRef}>
        <RankingNumber hidden={!ranking}>{ranking}</RankingNumber>
        <PlayButton onClick={togglePlay} disabled={!bgImageSrc}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </PlayButton>
        <ProgressBar $progress={progress} />
        <InfoOverlay>
          <h3>{song.song_title}</h3>
          <BpmKeyContainer>
            <BpmKeyPill>{song.song_bpm}</BpmKeyPill>
            <BpmKeyPill>{song.song_key}</BpmKeyPill>
          </BpmKeyContainer>
        </InfoOverlay>
        {audioSrc && <audio ref={audioRef} src={audioSrc} />}
      </CardLink>
    );
  }
  
  export default SongCard;