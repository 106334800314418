import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E3E3; /* Pale Stone Gray */
`;

const Title = styled.h1`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  margin-bottom: 10px;
  text-align: center;
`;

const Description = styled.p`
  font-family: 'Rubik', sans-serif;
  color: #2E2E2E;
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  max-width: 600px;
`;

const Highlight = styled.span`
  color: #6A7D8B; /* Muted Slate Blue */
  font-weight: bold;
`;

function AboutPage() {
  return (
    <PageContainer>
      <Title>About Us</Title>
      <Description>
        Welcome to <Highlight>tempo stock</Highlight>, your one-stop destination for high-quality, royalty-free music.
        Whether you're a content creator, filmmaker, or just looking for the perfect track, we've got you covered.
        <br></br>
        <br></br>
        Say hey or report a problem at <a href="mailto:hello@tempostock.com"><Highlight>hello@tempostock.com</Highlight></a>.
      </Description>
    </PageContainer>
  );
}

export default AboutPage;