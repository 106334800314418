// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import SongPage from './pages/SongPage';
import CategoryPage from './pages/CategoryPage';
import FeaturedPage from './pages/FeaturedPage';
import SearchResultsPage from './pages/SearchResultsPage';
import Login from './pages/Login';
import UserPage from './pages/UserPage';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import { SongProvider } from './SongContext';
import PrivateRoute from './components/PrivateRoute';
import TOS from './pages/TOS';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <SongProvider>
      <Router>
        <GlobalStyles />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/songs/:songId" element={<SongPage />} />
          <Route path="/:category" element={<CategoryPage />} />
          <Route path="/featured" element={<FeaturedPage />} />
          <Route path="/search" element={<SearchResultsPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/user"
            element={
              <PrivateRoute>
                <UserPage />
              </PrivateRoute>
            }
          />
          {/* Temporary TOS and Privacy Policy Routes */}
          <Route path="/tos" element={<TOS />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </Router>
    </SongProvider>
  );
}

export default App;