// src/pages/ForgotPassword.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ForgotPasswordContainer = styled.div`
  max-width: 400px;
  margin: 50px auto;
  padding: 40px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Rubik', sans-serif;

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
    font-family: 'Rubik', sans-serif;
  }

  input[type="email"] {
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    transition: border-color 0.3s;

    &:focus {
      border-color: #2e2e2e;
      outline: none;
    }
  }

  button {
    padding: 12px;
    background-color: #2e2e2e;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    transition: background-color 0.3s;

    &:hover {
      background-color: #444;
    }

    &:disabled {
      background-color: #aaa;
      cursor: not-allowed;
    }
  }
`;

const Message = styled.p`
  color: #388e3c; /* Green */
  font-size: 14px;
  margin-bottom: 15px;
  font-family: 'Rubik', sans-serif;
`;

const ErrorMessage = styled.p`
  color: #d32f2f; /* Red */
  font-size: 14px;
  margin-bottom: 15px;
  font-family: 'Rubik', sans-serif;
`;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/request-reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Password reset link has been sent to your email.');
      } else {
        setError(data.error || 'Failed to send reset link.');
      }
    } catch (err) {
      setError('Please email us at hello@tempostock.com to reset your password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ForgotPasswordContainer>
      <h2 style={{ textAlign: 'center', fontFamily: 'Rubik, sans-serif', color: '#2e2e2e' }}>Forgot Password</h2>
      <Form onSubmit={handleRequestReset}>
        {message && <Message>{message}</Message>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <label htmlFor="email">Enter your email address:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder="your.email@example.com"
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Sending...' : 'Send Reset Link'}
        </button>
      </Form>
      <Link to="/login" style={{ marginTop: '20px', display: 'block', textAlign: 'center', color: '#2e2e2e', textDecoration: 'underline' }}>
        Back to Login
      </Link>
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;