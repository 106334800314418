// src/components/MoodSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  FaSadTear,
  FaLeaf,
  FaHeart,
  FaBolt,
  FaQuestionCircle,
  FaSmile,
  FaPeace,
  FaMountain,
  FaSun,
  FaFire
} from 'react-icons/fa';

const INITIAL_COLOR = '#2E2E2E';

const moods = [
  { name: 'Epic', icon: FaMountain, color: '#800000' },      // Maroon
  { name: 'Sad', icon: FaSadTear, color: '#00008B' },        // Dark Blue
  { name: 'Uplifting', icon: FaSun, color: '#556B2F' },  // Dark Olive Green
  { name: 'Calm', icon: FaLeaf, color: '#4682B4' },          // Steel Blue
  { name: 'Romantic', icon: FaHeart, color: '#8B0000' },     // Dark Red
  { name: 'Energetic', icon: FaBolt, color: '#B22222' },     // Firebrick
  { name: 'Mysterious', icon: FaQuestionCircle, color: '#4B0082' }, // Indigo
  { name: 'Happy', icon: FaSmile, color: '#DAA520' },        // Goldenrod
  { name: 'Peaceful', icon: FaPeace, color: '#2E8B57' },     // Sea Green
  { name: 'Angry', icon: FaFire, color: '#8B0000' },   // Dark Red
];

// Styled Components

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align horizontally */
  margin: 50px 0px;

  h2 {
    margin-bottom: 20px;
    color: ${INITIAL_COLOR};
  }

  @media (max-width: 768px) {
    padding: 0 15px; /* Add padding for small screens */
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 0 30px; /* Add more padding for tablets */
  }
`;

const BlocksContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1300px;
  margin: 0 auto;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)); /* Two blocks per row */
    gap: 10px;
    padding: 0 15px;
    justify-content: center;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
    padding: 0 30px;
    justify-content: center;
  }
`;

// Updated MoodBlock with initial dark grey border and hover effect
const MoodBlock = styled(Link)`
  width: 200px;
  height: 200px;
  margin: 15px;
  border: 3px solid ${INITIAL_COLOR}; /* Initial dark grey border */
  border-radius: 20px;
  text-decoration: none;
  color: ${INITIAL_COLOR}; /* Initial dark grey text */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 0.3s ease, border 0.3s ease, color 0.3s ease;

  /* Shader Overlay */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.15); /* Light semi-transparent overlay */
    border-radius: 20px;
    transition: background 0.3s ease, backdrop-filter 0.3s ease;
  }

  &:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    border: 3px solid ${(props) => props.color}; /* Change border color on hover */
    color: ${(props) => props.color}; /* Change text and icon color on hover */
  }

  @media (max-width: 768px) {
    width: 125px;
    height: 125px;
    margin: 10px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 175px; /* Slightly larger for tablets */
    height: 175px;
    margin: 12px;
  }
`;

const MoodIconContainer = styled.div`
  position: relative;
  z-index: 1;
  font-size: 3rem;
  transition: color 0.3s ease;
  color: ${INITIAL_COLOR};

  ${MoodBlock}:hover & {
    color: ${(props) => props.color};
  }

  svg {
    width: 1em;
    height: 1em;
  }

  @media (max-width: 768px) {
    font-size: 2.25rem;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 2.8rem;
  }
`;

const MoodText = styled.span`
  position: absolute;
  bottom: 15px;
  font-size: 16px;
  color: ${INITIAL_COLOR}; /* Initial dark grey text */
  font-weight: bold;
  transition: color 0.3s ease;

  /* Change text color on hover by targeting MoodBlock's hover state */
  ${MoodBlock}:hover & {
    color: ${(props) => props.color}; /* Change text color on hover */
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 15px;
  }
`;

// MoodSection Component
function MoodSection() {
  return (
    <SectionContainer>
      <h2>Moods</h2>
      <BlocksContainer>
        {moods.map((mood) => {
          const IconComponent = mood.icon;
          return (
            <MoodBlock to={`/${mood.name.toLowerCase()}`} key={mood.name} color={mood.color}>
              <MoodIconContainer color={mood.color}>
                <IconComponent />
              </MoodIconContainer>
              <MoodText color={mood.color}>{mood.name}</MoodText>
            </MoodBlock>
          );
        })}
      </BlocksContainer>
    </SectionContainer>
  );
}

export default MoodSection;